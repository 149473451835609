import React, { useEffect } from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import AOS from "aos";
// import "aos/dist/aos.css";

import Web3Image from "../../../components/images/BlogContentImages/BlockChainAndMetaverse.jpg";
import WebImage1 from "../../../components/images/BlogContentImages/Web3Image.jpg";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";

import { Helmet } from "react-helmet";

const BlockChainAndMetaverse = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);


  const titleStyle = {
    fontSize: "24px",
    fontFamily: "poppins",
    fontWeight: "600",
  };
  const subStyle = {
    fontSize: "18px",
    fontFamily: "poppins",
    fontWeight: "600",
  }

  const textStyle = {
    fontSize: "14px",
    fontFamily: "poppins",
    mb: "10px",
  };

  return (
    <div>
      <Helmet>
        <title>{"Web3 - From Blockchain to Metaverse – The Next Internet Evolution"}</title>
        <meta
          name="description"
          content="Explore the evolution of the internet with Web3, focusing on blockchain, cryptocurrency, NFTs, and the metaverse."
        />
        <link rel="canonical" href="https://yourwebsite.com/web3-blockchain-metaverse" />
      </Helmet>

      <Header />

      <Container>
        <Box
          sx={{
            color: "#000",
            mt: "2rem",
            ...titleStyle,
          }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Typography sx={titleStyle}>
            Web3 - From Blockchain to Metaverse – The Next Internet Evolution
          </Typography>
          <Typography sx={textStyle}>
            Fri Dec 27 2024
          </Typography>
         
        </Box>

        <Grid container spacing={2} alignItems="center">
            <Grid item xs={14} md={7}>
            <Typography
            variant="body1"
            sx={textStyle}
          >
            The internet is evolving once again, and Web3 is leading the way. But what exactly is Web3? Let’s break it down.
          </Typography>
              <Typography sx={textStyle}>
              Web3 is a collection of technologies reshaping the internet. It’s centered on decentralization, giving more control to users rather than centralized tech giants. It spans cryptocurrencies, blockchain, NFTs, and the Metaverse, aiming to create a more user-driven internet.
              </Typography>
            </Grid>
            <Grid item xs={10} md={5}>
              <Typography
                component="img"
                src={Web3Image}
                alt="Agile Web Development"
                sx={{
                  display: "block",
                  width: "100%",
                  borderRadius: "8px",
                
                }}
              />
            </Grid>
          </Grid>

        <Box >
          <Typography sx={titleStyle}>
            Key Concepts of Web3
          </Typography>

          <Typography sx={subStyle} >
            1. Blockchain
          </Typography>
          <Typography sx={textStyle}>
            Blockchain is a decentralized public ledger where transactions are transparent and secure.
          </Typography>

          <Typography sx={textStyle}>
            <strong>Examples:</strong>
            <ul style={{ listStyleType: "square", paddingLeft: "1.5rem", }}>
              <li>Bitcoin – A decentralized digital currency.</li>
              <li>Ethereum – A platform for smart contracts and decentralized applications.</li>
            </ul>
          </Typography>

          <Typography sx={subStyle}>
            2. Cryptocurrency
          </Typography>
          <Typography sx={textStyle}>
            Cryptocurrencies allow peer-to-peer transactions without the need for banks.
          </Typography>

          <Typography sx={textStyle}>
            <strong>Example:</strong> ETH (Ethereum’s native cryptocurrency).
          </Typography>

          <Typography sx={subStyle}>
            3. NFTs (Non-Fungible Tokens)
          </Typography>
          <Typography sx={textStyle}>
            NFTs represent unique digital assets, from art to virtual real estate, and cannot be exchanged like currency.
          </Typography>
          <Typography sx={textStyle}>
            <strong>Examples:</strong>
            <ul style={{ listStyleType: "square", paddingLeft: "1.5rem", marginBottom: "1.5rem" }}>
              <li>Bored Ape Yacht Club – Collectible digital art.</li>
              <li>Decentraland – Virtual land trading.</li>
            </ul>
          </Typography>

          <Typography sx={subStyle}>
            4. DAOs (Decentralized Autonomous Organizations)
          </Typography>
          <Typography sx={textStyle}>
            DAOs are communities that make decisions collectively, governed by smart contracts.
          </Typography>
          <Typography sx={textStyle}>
            <strong>Examples:</strong>
            <ul style={{ listStyleType: "square", paddingLeft: "1.5rem", }}>
              <li>ConstitutionDAO – A group aiming to buy the U.S. Constitution.</li>
              <li>Decentraland DAO – Governs the Decentraland virtual world.</li>
            </ul>
          </Typography>

          <Typography sx={subStyle}>
            5. DeFi (Decentralized Finance)
          </Typography>
          <Typography sx={textStyle}>
            DeFi apps remove intermediaries like banks, allowing for direct borrowing, lending, and trading.
          </Typography>
          <Typography sx={textStyle}>
            <strong>Examples:</strong>
            <ul style={{ listStyleType: "square", paddingLeft: "1.5rem", marginBottom: "1.5rem" }}>
              <li>Uniswap – A decentralized crypto exchange.</li>
              <li>Compound – A platform for lending and borrowing crypto.</li>
            </ul>
          </Typography>

          <Grid container spacing={2} alignItems="center">
  {/* Text Content */}
  <Grid item xs={12} md={7}>
    <Typography sx={subStyle}>
      6. Metaverse
    </Typography>
    <Typography sx={textStyle}>
      The Metaverse is an immersive virtual world where users can interact, work, and play, often with VR/AR technology.
    </Typography>
    <Typography sx={textStyle}>
      <strong>Examples:</strong>
      <ul
        style={{
          listStyleType: "square",
          paddingLeft: "1.5rem",
        }}
      >
        <li>Sandbox – A world for user-created experiences.</li>
        <li>Decentraland – Virtual spaces and events.</li>
      </ul>
    </Typography>
  </Grid>

  {/* Image Content */}
  <Grid item xs={12} md={5}>
    <Box>
      <img
        src={WebImage1}
        alt="Metaverse Example"
        style={{
          width: "100%",
          maxWidth: "500px",
          height: "auto",
          borderRadius: "8px",
        }}
      />
    </Box>
  </Grid>
</Grid>

          {/* Additional Content */}
          <Box sx={{ mb: "30px", mt: "20px" }}>
            <Typography sx={titleStyle}>
              Why Web3 Matters
            </Typography>
            <Typography sx={textStyle}>
              Web3 isn’t just about new technology; it’s a movement toward decentralizing the internet. This shift allows users to own their data, control digital assets, and govern online communities.
            </Typography>

            <Typography sx={textStyle}>
              Web3 has the potential to -
              <ul style={{ listStyleType: "square", paddingLeft: "1.5rem", marginBottom: "1.5rem" }}>
                <li>Give Users Control – Decentralization puts power back into the hands of users.</li>
                <li>Create New Opportunities – Web3 opens new doors for creators, investors, and users alike.</li>
                <li>Promote Transparency – Blockchain ensures all transactions and ownerships are verifiable.</li>
              </ul>
            </Typography>

            <Typography sx={titleStyle}>
              What’s Next?
            </Typography>
            <Typography sx={textStyle}>
              The Web3 space is still developing, but it’s clear that the internet is moving towards decentralization. Whether you’re a developer, artist, or entrepreneur, now is the perfect time to explore the potential of Web3.
            </Typography>

            <Typography sx={titleStyle}>
              Conclusion
            </Typography>
            <Typography sx={textStyle}>
              Web3 represents a significant shift in how the internet functions, offering a decentralized, user-controlled alternative to the current centralized systems. With technologies like blockchain, cryptocurrency, NFTs, and the Metaverse, the future of the web is more open and transparent than ever before. As Web3 continues to evolve, businesses and individuals alike can take advantage of these opportunities to shape the next generation of the internet.
            </Typography>

            <Typography sx={textStyle}>
              Himotech Global has been at the forefront of IT and project management since 2021. We help businesses harness the power of technology to stay ahead in the digital world. Reach out today to learn more about how Web3 can reshape your business.
            </Typography>
          </Box>
        </Box>
      </Container>

      <Footer />
    </div>
  );
};

export default BlockChainAndMetaverse;
