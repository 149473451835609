import React, { useEffect } from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Img1 from "../../../components/images/BlogContentImages/AgileWebDevelopment.jpg";
import Img2 from "../../../components/images/BlogContentImages/AgileWebDevelopmentMethodology.jpg";
import Img3 from "../../../components/images/BlogContentImages/AgilevsWaterfall.jpg";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { Helmet } from "react-helmet";

const styles = {
  container: {
    color: "#000",
    marginTop: "2rem",
    fontSize: "24px",
    fontFamily: "poppins",
    fontWeight: "600",
  },
  title: {
    fontSize: "24px",
    fontFamily: "poppins",
    fontWeight: "600",
    color: "#2d2d2d",
   
   
  },
  introText: {
    fontSize: "14px",
    fontFamily: "poppins",
    color: "#34495e",
    marginBottom: "20px",
  },
  image: {
    display: "block",
    width: "100%",
    borderRadius: "8px",
  },

  image2:{
    display: "block",
    width: "90%",
    borderRadius: "8px",
    marginBottom:"10px"
    
  },
  image3:{
    display: "block",
    width: "90%",
    borderRadius: "8px",
      marginBottom:"10px"
  
  },

  heading: {
    fontSize: "24px",
    fontFamily: "poppins",
  },
  subheading: {
    fontSize: "18px",
    fontFamily: "poppins",
  },
  paragraph: {
    fontSize: "14px",
    fontFamily: "poppins",
    mb: "10px",
   
  },
  list: {
    listStyleType: "square",
    paddingLeft: "1.5rem",
  
  },
  // imageContainer: {
  //   marginBottom: "4rem",
  // },
  finalText: {
    fontSize: "24px",
    fontFamily: "poppins",
  },
  footer: {
    marginTop: "2rem",
  },
  strongText: {
    fontWeight: "bold",
    color: "#000",
  },
  normalText: {
    fontWeight: "normal",
    color: "#666",
  },

 


};

const WebDevelopment = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{"Agile Web Development - Everything You Need to Know"}</title>
        <meta
          name="description"
          content="Explore the concept of Agile web development, its methodology, and the key differences between Agile and Waterfall development, along with popular frameworks like Scrum and Kanban."
        />
        <link
          rel="canonical"
          href="https://himotechglobal.com/blog/agile-web-development"
        />
      </Helmet>

      <Header />

      <Container sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Box
          sx={styles.container}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {/* Title Section */}
          <Typography sx={styles.title}>
            Agile Web Development - Everything You Need to Know
          </Typography>
         <Typography
                     sx={{
                      fontSize: "14px",
    fontFamily: "poppins",
    paddingBottom: "1rem",
    fontWeight: "600",
    marginBottom: "2rem",
                     }}
                   >
                  Mon Dec 30 2024
                   </Typography>
          
          {/* Introduction Section */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography sx={styles.introText}>
                Agile web development is a process that relies on collaboration
                and adaptability to create software solutions. It emphasizes
                continuous improvement and flexibility in making adjustments
                during the development cycle.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                component="img"
                src={Img1}
                alt="Agile Web Development"
                sx={styles.image}
              />
            </Grid>
          </Grid>

          {/* What is Agile Development Section */}
          <Typography sx={styles.heading}>
            What is Agile Development?
          </Typography>
          <Typography sx={styles.paragraph}>
            Agile development is a methodology developed in 2001, outlined in
            the Manifesto for Agile Software Development. It is based on four
            principles:
            <ul style={styles.list}>
              <li>Focus on individuals and interactions over processes and tools.</li>
              <li>Prioritize working software over detailed documentation.</li>
              <li>Engage in customer collaboration throughout the development process.</li>
              <li>Be flexible in responding to change instead of rigidly following a plan.</li>
            </ul>
            The key difference between agile and traditional methods is the
            programmer's involvement from the initial stages. Agile encourages
            addressing challenges incrementally during development rather than
            making significant changes near the launch.
          </Typography>

          {/* Agile Web Development Methodology Section */}
          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
      {/* Heading */}
      <Grid item xs={12}>
        <Typography sx={styles.heading}>
          Agile Web Development Methodology
        </Typography>
      </Grid>

      {/* Image */}
      <Grid item xs={14} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Typography sx={styles.paragraph}>
    Agile web development involves simultaneous processes and frequent
    collaboration. The work is divided into smaller cycles, called
    "sprints," each with specific goals:
    <ul style={styles.list}>
      <li><strong>Discover -</strong> Understand project requirements.</li>
      <li><strong>Design -</strong> Create wireframes or prototypes.</li>
      <li><strong>Develop -</strong> Build features and functionality.</li>
      <li><strong>Test -</strong> Conduct evaluations for improvements.</li>
    </ul>
    Each sprint allows for flexibility and quicker problem-solving,
    ensuring the product meets user expectations. Testing occurs
    throughout the process, helping refine the product continually.
  </Typography>
</Grid>


      {/* Paragraph */}
      <Grid item xs={10} sm={5}>
        <Typography
          component="img"
          src={Img2}
          alt="Agile Web Development Methodology"
          sx={styles.image2}
        />
      </Grid>
     
    </Grid>

         
    <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
  {/* Heading */}
  <Grid item xs={12}>
    <Typography sx={styles.heading}>
      Agile vs. Waterfall Development
    </Typography>
  </Grid>

  {/* Image */}
  

  {/* Paragraph */}
  <Grid item xs={14} sm={7}>
    <Typography sx={styles.paragraph}>
      <strong>Waterfall development</strong> is a traditional approach that follows a
      sequential process:
      <ul style={styles.list}>
        <li>Planning</li>
        <li>Requirements documentation</li>
        <li>Analysis</li>
        <li>Design</li>
        <li>Coding</li>
        <li>Testing</li>
        <li>Deployment</li>
        <li>Support</li>
      </ul>
      While waterfall ensures clear documentation and fixed expectations,
      it is less flexible and more time-consuming. Agile development, on
      the other hand, adapts to changes quickly and focuses on customer
      involvement at every stage.
    </Typography>
  </Grid>
  <Grid item xs={10} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Typography
      component="img"
      src={Img3}
      alt="Agile vs Waterfall Development"
      sx={styles.image3}
    />
  </Grid>
</Grid>








          {/* When to Use Waterfall Section */}
          <Typography sx={styles.heading}>
            When to Use Waterfall
          </Typography>
          <Typography sx={styles.paragraph}>
            Waterfall may be suitable when:
            <ul style={styles.list}>
              <li>Regulatory requirements demand extensive documentation.</li>
              <li>Stakeholders prefer minimal involvement during development.</li>
              <li>Firm deadlines cannot be adjusted.</li>
            </ul>
          </Typography>

          {/* Agile Development Frameworks Section */}
          <Typography sx={styles.heading}>
            Agile Development Frameworks
          </Typography>

          <Typography sx={styles.subheading}>
            Scrum
          </Typography>
          <Typography sx={styles.paragraph}>
            Scrum is a popular agile framework emphasizing teamwork and
            continuous improvement. It includes:
            <ul style={styles.list}>
              <li><strong>Product Backlog - </strong>Features and changes prioritized for completion.</li>
              <li><strong>Sprint Planning - </strong>Setting deliverables for upcoming sprints.</li>
              <li><strong>Sprint - </strong>Work period to achieve specific goals.</li>
              <li><strong>Scrum Meetings - </strong>Daily discussions to review progress and next steps.</li>
              <li><strong>Sprint Review - </strong>Evaluation of work completed during the sprint.</li>
            </ul>
          </Typography>

          {/* Footer Section */}
           {/* New Content Section */}
           <Typography sx={styles.heading}>
            When to Use Waterfall
          </Typography>
          <Typography sx={styles.paragraph}>
            Waterfall may be suitable when:
            <ul style={styles.list}>
              <li>Regulatory requirements demand extensive documentation.</li>
              <li>Stakeholders prefer minimal involvement during development.</li>
              <li>Firm deadlines cannot be adjusted.</li>
            </ul>
          </Typography>

          {/* Agile Development Frameworks Section */}
          <Typography sx={styles.heading}>
            Agile Development Frameworks
          </Typography>

          <Typography sx={styles.subheading}>
            Scrum
          </Typography>
          <Typography sx={styles.paragraph}>
            Scrum is a popular agile framework emphasizing teamwork and
            continuous improvement. It includes:
            <ul style={styles.list}>
              <li><strong>Product Backlog - </strong>Features and changes prioritized for completion.</li>
              <li><strong>Sprint Planning - </strong>Setting deliverables for upcoming sprints.</li>
              <li><strong>Sprint - </strong>Work period to achieve specific goals.</li>
              <li><strong>Scrum Meetings - </strong>Daily discussions to review progress and next steps.</li>
              <li><strong>Sprint Review - </strong>Evaluation of work completed during the sprint.</li>
            </ul>
          </Typography>

          <Typography sx={styles.subheading}>
            Kanban
          </Typography>
          <Typography sx={styles.paragraph}>
            Kanban is another agile framework that focuses on a lean approach with continuous delivery. Unlike Scrum, it does not have predefined roles or timelines, offering greater flexibility.
          </Typography>

          {/* Benefits of Agile Web Development */}
          <Typography sx={styles.heading}>
            Benefits of Agile Web Development
          </Typography>
          <Typography sx={styles.paragraph}>
            <ul style={styles.list}>
              <li><strong>Customer Collaboration -</strong> Regular feedback ensures the product aligns with customer needs.</li>
              <li><strong>Project Control -</strong> Teams track progress daily, allowing quick corrections.</li>
              <li><strong>Risk Reduction -</strong> Regular reviews minimize the chances of major failures.</li>
              <li><strong>Flexibility -</strong> Agile allows changes based on client input during the project.</li>
              <li><strong>Faster Production -</strong> Agile enables faster delivery through incremental sprints.</li>
            </ul>
          </Typography>

          
          <Typography sx={styles.heading}>
            Agile Support and Maintenance
          </Typography>
          <Typography sx={styles.paragraph}>
            Agile development extends beyond project completion. Regular maintenance ensures the software remains effective and adaptable to new challenges. Ongoing support involves collaborative problem-solving and continuous customer feedback.
          </Typography>

          {/* Final Thoughts */}
          <Typography sx={styles.heading}>
            Final Thoughts
          </Typography>
          <Typography sx={styles.paragraph}>
            Agile web development is a dynamic, collaborative approach that adapts to the evolving needs of businesses and customers. Its flexibility, efficiency, and focus on customer satisfaction make it a preferred choice for many organizations.
          </Typography>

          {/* Call to Action */}
          <Typography sx={styles.heading}>
            Looking for expert web development solutions?
          </Typography>
          <Typography sx={styles.paragraph}>
            Himotech Global has been delivering reliable services and efficient project management since 2021. Our team aims to make IT and technology accessible and usable for all. Partner with us to give your business a strong presence in the digital world.
          </Typography>

          <Typography sx={styles.paragraph}>
            Visit Himotech Global to learn more.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default WebDevelopment;
