import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { Box } from "@mui/material";
import AboutMore from "./pages/AboutMore";
import About from "./pages/About";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetailPage from "./pages/Blogs/BlogDetailPage";
import BlogDetailPage_2 from "./pages/Blogs/BlogDetailPage_2";
import BlogDetailPage_3 from "./pages/Blogs/BlogDetailPage_3";
import WebEvolutionBlog from "./pages/Blogs/WebEvolutionBlog";
import DevopsBlog from "./pages/Blogs/DevopsBlog";
import BlockchainBlog from "./pages/Blogs/BlockchainBlog";
import BlockchainEcosystem from "./pages/Blogs/BlockchainEcosystem";
import BlockchainRights from "./pages/Blogs/BlockchainRights";
import Convergence from "./pages/Blogs/Convergence";
import Web3Technology from "./pages/Blogs/Web3Technology";
import Web2VsWeb3 from "./pages/Blogs/Web2VsWeb3";
import DigitalAdvertisement from "./pages/Blogs/DigitalAdvertisement";
import DefiBlog from "./pages/Blogs/DefiBlog";
import BlockchainWithAi from "./pages/Blogs/BlockchainWithAi";
import Web3Management from "./pages/Blogs/Web3Management";
import SmartContract from "./pages/Blogs/SmartContract";
import Web3Metaverse from "./pages/Blogs/Web3Metaverse";
import TechPurchase from "./pages/Blogs/TechPurchase";
import ECommerce from "./pages/Blogs/ECommerce";
import BrandStrategy from "./pages/Blogs/BrandStrategy";
import WebsiteImprove from "./pages/Blogs/WebsiteImprove";
import EcommerceWebsite from "./pages/Blogs/EcommerceWebsite";
import EeatGuidline from "./pages/Blogs/EeatGuidline";

import Testing from "./pages/newservices/Testing";
import Web3 from "./pages/newservices/Web3";
import Blockchaindev from "./pages/newservices/Blockchaindev";
import Marketing from "./pages/newservices/Marketing";
import Devops from "./pages/newservices/Devops";
import Webdevelopment from "./pages/newservices/Webdevelopment";
import SecurityToken from "./pages/Blogs/SecurityToken";
import BlockchainMvpDev from "./pages/Blogs/BlockchainMvpDev";
import SeoVsSem from "./pages/Blogs/SeoVsSem";
import TestMangement from "./pages/Blogs/TestMangement";
import BitCoinPayment from "./pages/Blogs/BitCoinPayment";
import BlockChainAndMetaverse from "./pages/Blogs/BlogsContent/BlockChainAndMetaverse ";
import WebSiteImportance from "./pages/Blogs/BlogsContent/WebSiteImportance";
import WebDevelopment from "./pages/Blogs/BlogsContent/WebDevelopment";
import CryptoWalletsSafeGuarding from "./pages/Blogs/BlogsContent/CryptoWalletsSafeGuarding";
import AgileWebDevelopment from "./pages/Blogs/BlogsContent/AgileWebDevelopment";
function App() {
  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blog" element={<Blogs />} />
          <Route
            exact
            path="/blog/himotech-guide"
            element={<BlogDetailPage />}
          />
          <Route
            exact
            path="/blog/himotech-future"
            element={<BlogDetailPage_2 />}
          />
          <Route
            exact
            path="/blog/exploring-future"
            element={<BlogDetailPage_3 />}
          />
          <Route
            exact
            path="/blog/web-evolution"
            element={<WebEvolutionBlog />}
          />
          <Route exact path="/thank-you" element={<Services />} />
          <Route exact path="/blog/devops-service" element={<DevopsBlog />} />
          <Route exact path="/blog/blockchain" element={<BlockchainBlog />} />
          <Route
            path="/blog/blockchain-ecosystem"
            element={<BlockchainEcosystem />}
          />
          <Route
            path="/blog/blockchain-rights"
            element={<BlockchainRights />}
          />
          <Route
            path="/blog/digital-advertising"
            element={<DigitalAdvertisement />}
          />
          <Route
            path="/blog/blockchain-with-ai"
            element={<BlockchainWithAi />}
          />
          <Route path="/blog/web3-management" element={<Web3Management />} />
          <Route path="/blog/smart-contract" element={<SmartContract />} />
          <Route path="/blog/web3-metaverse" element={<Web3Metaverse />} />
          <Route path="/blog/defi" element={<DefiBlog />} />
          <Route path="/blog/web3-technology" element={<Web3Technology />} />
          <Route path="/blog/web2-vs-web3" element={<Web2VsWeb3 />} />
          <Route path="/blog/convergence" element={<Convergence />} />
          <Route path="/blog/tech-purchase" element={<TechPurchase />} />
          <Route path="/blog/e-commerce" element={<ECommerce />} />
          <Route path="/blog/brand-strategy" element={<BrandStrategy />} />
          <Route path="/blog/improve-website" element={<WebsiteImprove />} />
          <Route
            path="/blog/ecommerce-website"
            element={<EcommerceWebsite />}
          />
          <Route path="/blog/eeat-guidline" element={<EeatGuidline />} />
          <Route path="/blog/security-token" element={<SecurityToken />} />
          <Route
            path="/blog/blockchain-mvp-development"
            element={<BlockchainMvpDev />}
          />
          <Route path="/blog/seo-vs-sem" element={<SeoVsSem />} />
          <Route path="/blog/test-management" element={<TestMangement />} />
          <Route path="/blog/bitcoin-payment" element={<BitCoinPayment />} />
          <Route path="/aboutmore" element={<AboutMore />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/services/web-development"
            element={<Webdevelopment />}
          />
          <Route path="/services/testing" element={<Testing />} />
          <Route path="/services/web3" element={<Web3 />} />
          <Route path="/services/blockchain" element={<Blockchaindev />} />
          <Route path="/services/marketing" element={<Marketing />} />
          <Route path="/services/devops" element={<Devops />} />
          <Route
            path="/blog/blockchain-metaverse"
            element={<BlockChainAndMetaverse />}
          />
          <Route
            path="/blog/website-importance"
            element={<WebSiteImportance />}
          />
          <Route
            path="/blog/webdevelopment"
            element={<WebDevelopment />}
          />
          <Route
            path="/blog/cryptowallets-safeguarding"
            element={<CryptoWalletsSafeGuarding />}
          />
          <Route
            path="/blog/agile-webdevelopment"
            element={<AgileWebDevelopment />}
          />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
