import React, { useEffect } from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import Img1 from "../../../components/images/BlogContentImages/WebSiteImportance.jpg";
import Img2 from "../../../components/images/BlogContentImages/WebsiteMaintence.jpg";

import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { Helmet } from "react-helmet";

const WebsiteMaintenance = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const styles = {
    container: {
      color: "#000",
      marginTop: "2rem",
      fontSize: "24px",
      fontFamily: "poppins",
      fontWeight: "600",
    },
    title: {
      fontSize: "24px",
      fontFamily: "poppins",
      fontWeight: "600",
    },
    date: {
      fontSize: "14px",
      fontFamily: "poppins",
      paddingBottom: "1rem",
    
    },
    introText: {
      fontSize: "14px",
      fontFamily: "poppins",
 
    
     
    },
    image: {
      float: "right",
      padding: { md: "0.5rem 1rem 0rem 1rem", xs: "0 0 1rem" },
      width: { md: "100%", xs: "100%" },
      height: "auto",
    
    },
    image1: {
      float: "right",
      padding: { md: "0.5rem 1rem 0rem 1rem", xs: "0 0 1rem" },
      width: { md: "100%", xs: "100%" },
      height: "auto",
    },
    textBox: {
      flex: 1,
      paddingRight: "20px",
    },
    heading: {
      fontSize: "24px",
      fontFamily: "poppins",
      paddingBottom: "1rem",
      fontWeight: "600",
     
    },
    subheading: {
      fontSize: "18px",
      fontFamily: "poppins",
     
      fontWeight: "600",
      
    },
    paragraph: {
      fontSize: "14px",
      fontFamily: "poppins",
      paddingBottom: "1rem",
     
      
    },
    list: {
      listStyleType: "square",
      paddingLeft: "1.5rem",
    
    },
  
    finalText: {
      fontSize: "24px",
      fontFamily: "poppins",
      paddingBottom: "1rem",
      fontWeight: "600",
     
    },
   
    footer: {
      marginTop: "2rem",
    },
    strongText: {
      fontWeight: "bold", // Bold for the <strong> text
      color: "#000", // You can adjust this color as needed
    },
    normalText: {
      fontWeight: "normal", // Lighter weight for the remaining content
      color: "#666", // Light color for the normal text
    },
  };

  return (
    <div>
      <Helmet>
        <title>
          {"How Web Development Enhances Opportunities in Direct Selling"}
        </title>
        <meta
          name="description"
          content="Discover how web development empowers direct selling businesses with digital tools, rural development, influencer marketing, and more."
        />
        <link
          rel="canonical"
          href="https://himotechglobal.com/blog/web-development-direct-selling"
        />
      </Helmet>

      <Header />

      <div className="container-fuild">
        <Box
          sx={{
            color: "#000",
            mt: "2rem",
            mx: "auto",
            p: "2rem",
            maxWidth: "1200px",
            background: "none",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {/* Title Section */}
          <Typography sx={styles.title}>
            Why is Website Maintenance Important?
          </Typography>
         <Typography
                     sx={{
                       fontSize: "14px",
                       fontFamily: "poppins",
                       pb: "1rem",
                       fontWeight: "600",
                     }}
                   >
                     Fri Dec 28 2024
                   </Typography>



          {/* Improved User Engagement Section */}
          <Grid container alignItems="center">
            <Grid item xs={14} md={7}>
              <Typography sx={{ ...styles.heading }}>
               
                {/* Increase font size for subheading */}
                Improved User Engagement
              </Typography>
              <Typography sx={{ ...styles.paragraph }}>
                
                {/* Increase font size for paragraph */}A website that works
                smoothly offers a better experience to visitors. This helps
                users interact with the content more effectively, encouraging
                them to visit again. Regular maintenance ensures that your site
                stays user-friendly and meets visitor expectations.
              </Typography>
            </Grid>
            <Grid item xs={10} md={5}>
              <Box
                component="img"
                src={Img1}
                alt="Improved User Engagement"
                sx={{ ...styles.image, maxWidth: "90%", height: "auto" }} // Decrease image size by adjusting width
              />
            </Grid>
          </Grid>

          {/* Benefits Section */}
          <Box>
            {[
              {
                title: "Faster Load Times",
                content:
                  "The speed of your website significantly impacts user satisfaction. Websites that load quickly keep visitors engaged and reduce the chances of them leaving early. Regular performance checks and updates can help achieve this.",
              },
              {
                title: "Enhanced Security",
                content:
                  "Cybersecurity is a critical concern for any website. Regular updates and security patches protect your site from potential threats and keep sensitive information safe.",
              },
              {
                title: "Better SEO",
                content:
                  "Search engines prefer websites that are up-to-date and optimized. A well-maintained website ranks higher in search results, improving visibility and attracting more traffic.",
              },
              {
                title: "Professional Image",
                content:
                  "A website represents your business online. Keeping it updated and functional reflects professionalism, which builds trust with customers and clients.",
              },
              {
                title: "Avoid Downtime",
                content:
                  "Unexpected issues can cause your website to go offline. Regular maintenance helps identify and fix potential problems before they lead to downtime, saving you from lost revenue or customer dissatisfaction.",
              },
            ].map(({ title, content }, index) => (
              <Box key={index} >
                <Typography sx={styles.subheading}>{title}</Typography>
                <Typography sx={styles.paragraph}>{content}</Typography>
              </Box>
            ))}
          </Box>

          <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
  {/* Heading */}
  <Grid item xs={12}>
    <Typography
      sx={{
        fontSize: "24px",
        fontFamily: "Poppins",
        fontWeight: "bold",
        mb: "1rem",
      }}
    >
      Tasks Involved in Website Maintenance
    </Typography>
  </Grid>

  {/* Image */}
 

  {/* Website Maintenance Tasks */}
  <Grid item xs={14} sm={7} sx={{ display: 'flex', alignItems:"center" }}>
    <Box>
      {/* Weekly Checks */}
      <Box sx={{ mb: "1rem" }}>
        <Typography sx={styles.subheading}>Weekly Checks</Typography>
        <ul style={styles.list}>
          <li >Test the site for speed and performance.</li>
          <li >Update software, plugins, and themes.</li>
          <li >Remove spam comments or unwanted content.</li>
          <li >Fix broken links.</li>
        </ul>
      </Box>

    
    </Box>
  </Grid>
  <Grid item xs={10} sm={5} >
    <Typography
      component="img"
      src={Img2}
      alt="Opportunities in Direct Selling"
      sx={{
        ...styles.image1,
        maxWidth: "90%",
        height: "auto",
      }} 
    />
  </Grid>
</Grid>
  {/* Monthly Checks */}
  <Box sx={{ mb: "1rem" }}>
        <Typography sx={styles.subheading}>Monthly Checks</Typography>
        <ul style={styles.list}>
          <li >Conduct detailed security scans.</li>
          <li >Analyze user engagement metrics.</li>
          <li >Review and update website content.</li>
          <li >Ensure the site meets loading speed benchmarks.</li>
        </ul>
      </Box>

      {/* Quarterly Checks */}
      <Box sx={{ mb: "1rem" }}>
        <Typography sx={styles.subheading}>Quarterly Checks</Typography>
        <ul style={styles.list}>
          <li >Evaluate your SEO strategies and marketing efforts.</li>
          <li >Test the site for mobile optimization.</li>
          <li  >Review and improve the overall user experience.</li>
        </ul>
      </Box>

      {/* Annual Checks */}
      <Box>
        <Typography sx={styles.subheading}>Annual Checks</Typography>
        <ul style={styles.list}>
          <li>Audit all website content for relevance and accuracy.</li>
          <li >Renew domain registrations and SSL certificates.</li>
          <li >Clean email subscription lists.</li>
          <li >Evaluate and refresh the website design.</li>
        </ul>
      </Box>



          {/* Conclusion and Call to Action */}
          <Box>
            <Typography sx={styles.subheading}>Conclusion</Typography>

            <Typography sx={styles.paragraph}>
              Maintaining your website regularly is crucial for ensuring it
              performs well. From improving user engagement and protecting
              against security risks to boosting search engine rankings, proper
              maintenance supports your business's online presence and success.
            </Typography>
            <Typography sx={styles.subheading}>Call to Action</Typography>
            <Typography sx={styles.paragraph}>
              Himotech Global has been a trusted name in IT since 2021, offering
              reliable services and efficient project management. We help
              businesses establish a strong online presence and adopt the best
              digital solutions. Our goal is to make IT and technology
              accessible and usable for everyone. Get in touch with us to learn
              how our services can support your business goals and take your
              digital presence to the next level.
            </Typography>
          </Box>
        </Box>
      </div>

      <Footer />
    </div>
  );
};

export default WebsiteMaintenance;
