import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import img_1 from "../../components/images/Service/Devops/img_1.jpg";
import img_2 from "../../components/images/Service/Devops/img_2.jpg";
import img_3 from "../../components/images/Service/Devops/img_3.jpg";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Helmet } from "react-helmet";

const Devops = () => {
  useEffect(() => {
    AOS.init();
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          {"Himotech Global: Your Guide to Tech Trends and Insights"}
        </title>
        <meta
          name="description"
          content={
            "Explore Himotech Global's ultimate guide to current tech trends and insights. Stay ahead with expert analysis, predictions, and industry updates on topics ranging from AI to blockchain and beyond."
          }
        />
        <link
          rel="canonical"
          href="https://himotechglobal.com/blog/himotech-guide"
        />
      </Helmet>
      <Header />
      <div class="container">
      <Typography
          sx={{
            fontSize: "26px",
            fontFamily: "poppins",
            pb: "1rem",
          }}
        >
          <b>DEVOPS SERVICES</b>
         
        </Typography>

        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
            textAlign: "justify",
          }}
        >
          <b> DevOps </b>is a transformative approach to software development
          and deployment that bridges the gap between development and operations
          teams. By fostering a culture of continuous improvement,{" "}
          <b>Himotech Global</b> helps businesses achieve rapid delivery and
          superior quality in their software solutions. Our DevOps services
          enable you to capture market opportunities with greater agility,
          continuously deploy software, scale operations seamlessly, and build a
          cohesive team culture. The outcome is a more consistent, secure, and
          stable product environment that optimizes costs and drives exponential
          growth.
        </Typography>
        <Typography
          component={"img"}
          src={img_3}
          // width={"100%"}
          sx={{
            float: "right",
            p: { md: "0.5rem 1rem 0rem 1rem", xs: "0 0 1rem" },
            width: { md: "50%", xs: "100%" },
          }}
          height={"100%"}
        />
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
            textAlign: "justify",
          }}
        >
          <b style={{fontSize:"24px"}}>Our Core DevOps Consulting Offerings</b>
          <br></br>
          <br></br>
          <b style={{fontSize:"24px"}}>1. DevOps Consulting and Training</b>
          <br></br>
          <br></br>
          At <b>Himotech Global,</b> we offer comprehensive{" "}
          <b>Cloud Automation</b> and <b>Architecture Assessments</b> to guide
          your DevOps journey. Our expertise in tools such as{" "}
          <b>Chef, Terraform, and Docker</b> ensures a smooth and efficient
          development process. We provide the necessary training and support to
          help your team adopt these essential DevOps tools effectively
        </Typography>

        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "poppins",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          2. Infrastructure Automation
        </Typography>

        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
            textAlign: "justify",
          }}
        >
          We help automate your infrastructure setup from start to finish,
          including<b> OS installations</b> and
          <b>server configurations.</b> This end-to-end automation ensures
          seamless operation and communication across your environments,
          enhancing overall efficiency.
        </Typography>

        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
          }}
        >
          <b style={{fontSize:"24px"}}> 3. DevOps Factory Solution</b>
          <br></br>
          <br></br>
          Our <b>DevOps Factory Solution</b> automates the entire software
          delivery lifecycle. This approach improves collaboration and
          monitoring, accelerates delivery, and strengthens security. By
          integrating automation into your development processes, we facilitate
          faster and more reliable software deployments
        </Typography>
        <Typography
          component={"img"}
          src={img_1}
          // width={"100%"}
          sx={{
            float: "right",
            p: { md: "0.5rem 1rem 0rem 1rem", xs: "0 0 1rem" },
            width: { md: "50%", xs: "100%" },
          }}
          height={"100%"}
        />
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "poppins",
            pb: "1rem",
            fontWeight: "600",
          }}
        >
          4. Automated Security and Compliance
        </Typography>

        <Typography
          // component={"ul"}
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
            textAlign: "justify",
            // pl: "1.5rem",
          }}
        >
          <b>Himotech Global</b> offers automated security and compliance
          solutions that adhere to industry standards such as <b>PCI, HIPAA,</b>{" "}
          and <b>FEDRAMP.</b> Our suite of tools helps identify vulnerabilities
          and automates the remediation process, ensuring that your systems
          remain secure and compliant{" "}
        </Typography>
        <Typography
          // component={"ul"}
          sx={{
            fontSize: "24px",
            fontFamily: "poppins",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <b>5. Containers and Microservices</b>
        </Typography>
        <Typography
          // component={"ul"}
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "1rem",
            textAlign: "justify",

            // pl: "1.5rem",
          }}
        >
          We assist in adopting a modular approach to application development
          through <b>containers</b> and
          <b>microservices.</b> This method allows you to develop, test, and
          scale features independently, enhancing your agility and scalability
          in the software development lifecycle.
        </Typography>
        <Typography
          // component={"ul"}
          sx={{
            fontSize: "24px",
            fontFamily: "poppins",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          <b> 6. CI/CD Pipelines</b>
        </Typography>
        <Typography
          // component={"ul"}
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            textAlign: "justify",
            pb: "1rem",
            // pl: "1.5rem",
          }}
        >
          Our <b>Continuous Integration and Continuous Deployment (CI/CD)</b>{" "}
          solutions streamline your software delivery process. By automating
          build, test, and deployment phases, we facilitate frequent,
          high-quality releases and significantly reduce time-to-market.
        </Typography>

        <Typography
          component={"ul"}
          sx={{
            py: "1rem",
            pl: "0rem",
          }}
        ></Typography>
        {/* <Typography
            component={"img"}
            src={blog_3}
            // width={"100%"}
            sx={{ float: "right",p:{md:"0 1rem 0rem 1rem",xs:"0 0 1rem"}, width: { md: "40%", xs: "100%" } }}
            height={"100%"}
            pb={"1rem"}
          /> */}
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "poppins",
            pb: "2rem",
            textAlign: "justify",
          }}
        >
          <b></b>
          <br></br>
          <br></br>
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default Devops;
